<template>
    <span>
        {{ duration }}
        <Tooltip 
            v-if="showTooltip" 
            :data="tooltipText" 
        />
    </span>
</template>

<script>
import Tooltip from '@/components/features/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        model: {
            type: Object,
            required: true
        },
        dataType: {
            type: String,
            required: false,
            default: ''
        },
        dataDisplay: {
            type: String,
            required: false,
            default: ''
        },
    },
    computed: {
        duration() {
            return this.dataType === 'debt_collection' && this.dataDisplay === 'override' 
                ? this.model.getDurationDebtCollectionString(this.$translate('month_short', 'mån'), this.$translate('year', 'år'))
                : this.model.getDurationString(this.$translate('month_short', 'mån'), this.$translate('year', 'år')); 
        },
        showTooltip() {
            return this.dataType === 'debt_collection' && this.dataDisplay === 'tooltip' && this.model.hasDebtCollectionDuration;
        },
        tooltipText() {
            return this.$translate('debt_collection_duration_tooltip', 'Vid ett samlingslån gäller istället {duration}')
                .replace('{duration}', this.model.getDurationDebtCollectionString(this.$translate('month_short', 'mån'), this.$translate('year', 'år')));
        }
    }
};
</script>