<template>
    <div class="lender-box">
        <nuxt-link
            :to="$prismic.linkResolver(lender)"
            class="lender-box__inner"
        >
            <PrismicImage
                :img="lender.data.image"
                :alt="lender.data.title"
                w="100"
                h="50"
            />
        </nuxt-link>

        <div
            v-if="lenderType === 'lender'"
            class="lender-box__inner"
        >
            <span>{{ $translate('interest', 'Ränta') }}</span>
            <span>{{ model.interestString }}</span>
        </div>

        <div
            v-else-if="lenderType === 'lender_corporate'"
            class="lender-box__inner"
        >
            <span>{{ $translate('duration', 'Löptid') }}</span>
            <span>{{ model.getDurationString($translate('month_short', 'mån'), $translate('year', 'år')) }}</span>
        </div>

        <div class="lender-box__inner">
            <span>{{ $translate('max_amount', 'Maxbelopp') }}</span>
            <alternate-loan-amount 
                :model="model" 
                :data-type="alternateDataConfig.dataType" 
                :data-display="alternateDataConfig.dataDisplay"
                data-key="maxAmountString"
            />
        </div>

        <div class="lender-box__inner">
            <ButtonTarget
                size="sm"
                background="green"
                :text="$translate('cta_to_application', 'Till ansökan')"
                :link="lender"
                placement="rich-text-lender-box"
            />
        </div>
        <div
            v-if="checkContent"
            class="list-item__content rich-text"
            v-html="$prismic.asHtml(content)"
        />
    </div>
</template>

<script>
import AlternateLoanAmount from '@/components/features/AlternateLoanAmount.vue';
export default {
    components: {
        AlternateLoanAmount
    },
    props: {
        id: {
            type: String,
            required: true
        },
        lenderType: {
            type: String,
            required: false,
            default: 'lender'
        },
        content: {
            type: Array, 
            required: false,
            default: () => []
        },
        alternateDataConfig: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    computed: {
        // NOTE: DO we add mortgage?
        model() {
            if (this.lenderType === 'lender') {
                return new this.$models.Loan(this.lender, this.$store);
            }
            else if (this.lenderType === 'lender_corporate') {
                return new this.$models.CorporateLoan(this.lender, this.$store);
            }
            return false;
        },

        lender() {
            if (this.lenderType === 'lender') {
                return this.$store.state.lenders[ this.id ];
            }
            else if (this.lenderType === 'lender_corporate') {
                return this.$store.state.lendersCorporate[ this.id ];
            }
            return false;
        },
        
        checkContent() {
            if (!this.content.length) {
                return false;
            }
            return this.content
                .map(content => content.text)
                .filter(text => text)
                .length;
        }
    }
};
</script>

<style lang="scss" scoped>
.lender-box {
    @include border;
    box-shadow: $bxs;
    @include border-radius(5px);
    @include spacing(padding, 4);
    @include grid(1, 30px);
    width: 100%;
    color: $font;

    @include device(pad) {
        @include grid(4, 10px);
    }

    .lender-box__inner {
        @include grid(1, 10px);
        text-align: center;

        &:first-child {
            @include flex;
        }

        span {
            font-size: 14px;

            &:last-child { font-weight: bold; }
        }

        a {
            @include flex;
            padding-top: 15px;
            padding-bottom: 15px;
        }

        img { @include size(100px, auto); }
    }
}
.list-item {
    width: 100%; 

    &__content {
        grid-column-start: 1;
        grid-column-end: 5;
        color: #6c757d;
    }
    &__content ::v-deep p {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0;
    }
    @include device(mobile) {
        &__content {
            grid-column-start: 1;
            grid-column-end: 2;
            color: #6c757d;
        }
        &__content ::v-deep p {
            font-size: 12px;
            line-height: 18px;
            margin: 0;
        }
    }
}
</style>
