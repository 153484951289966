<template>
    <Section width="lg">
        <div class="section-heading">
            <h2 v-if="$validateText(data.primary.h2)" class="section-header">
                {{ $prismic.asText(data.primary.h2) }}
            </h2>
            <div 
                v-if="$validateText(data.primary.teaser)" 
                class="rich-text"
                v-html="$prismic.asHtml(data.primary.teaser)" 
            />
        </div>
        <div class="calculator-container">
            <div class="calculator-box calculations">
                <h3>{{ inputSectionHeader }}</h3>
                <Slider
                    v-for="slider in sliders"
                    :key="slider.calcKey"
                    :label="slider.label"
                    :min-value="slider.minValue"
                    :max-value="slider.maxValue"
                    :increment="slider.increment"
                    :suffix="slider.suffix"
                    :calc-key="slider.calcKey"
                    :start-value="slider.startValue"
                    :max-decimals="slider.maxDecimals"
                    :max-fallback="slider.maxFallback"
                    @updateSliderValue="setValues"
                />
            </div>
            <results-box 
                :loan-amount="loanAmount"
                :interest-cost="interestCost"
                :interest-deduction="interestDeduction"
                :total-to-pay="totalToPay"
                :monthly-payments="monthlyPayments"
                :is-car-loan="isCarLoan"
            />
        </div>
        <div class="details-container">
            <div class="switch-container">
                <switch-checkbox 
                    :id="calculatorId"
                    @updateValue="(value) => showTable = value" 
                />
                <span>{{ $translate('calc_show_payment_breakdown', 'Visa betalnings nedbrytning') }}</span>
            </div>
            <details-table
                v-if="showTable"
                :loan-amount="loanAmount"
                :duration="duration"
                :interest="interest"
            />
        </div>
        <highlighted-lenders 
            v-if="showHighlightedLenders" 
            :loan-amount="loanAmount" 
            :duration="duration" 
            :header="data.primary.highlighted_lenders_header" 
            :alternate-data-config="{
                dataType: data.primary.alternate_data,
                dataDisplay: data.primary.alternate_data_display
            }"
        />
    </Section>
</template>

<script>
import Slider from '@/components/features/SliderAndInput.vue';
import SwitchCheckbox from '@/components/features/SwitchCheckbox.vue';
import DetailsTable from '@/components/slices/calculator/DetailsTable.vue';
import { pick } from 'lodash';
import HighlightedLenders from './HighlightedLenders.vue';
import ResultsBox from './ResultsBox.vue';

export default {
    components: {
        Slider,
        ResultsBox,
        DetailsTable,
        SwitchCheckbox,
        HighlightedLenders
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },

    data() {
        // initialise default values for initial amount and initial downpayment for calculating intial car cost
        const isCarLoan = this.data.primary.loan_type === 'car_loan';
        const defaultAmount = isCarLoan ? 150000 : 20000;
        const initialAmount = this.data.primary.initial_loan_amount || defaultAmount;
        const initialDownPayment = this.data.primary.initial_down_payment || 20;

        const calculatorValues = {
            interest: this.data.primary.initial_interest || 6,
            duration: this.data.primary.initial_duration || 60,
            loanAmount: initialAmount,
            carCost: initialAmount / (1 - initialDownPayment/100),
            downPayment: this.data.primary.initial_down_payment || 20,
        };

        const minMaxValues = {
            minLoanAmount: this.data.primary.minimum_loan_amount || 0,
            maxLoanAmount: this.data.primary.maximum_loan_amount || 600000,
            minCarCost: this.data.primary.minimum_loan_amount || 0,
            maxCarCost: this.data.primary.maximum_loan_amount || 1000000,
            minDownPayment: this.data.primary.minimum_down_payment || 20,
            maxDownPayment: this.data.primary.maximum_down_payment || 80,
            minInterest: this.data.primary.minimum_interest || 0,
            maxInterest: this.data.primary.maximum_interest || 50,
            minDuration: this.data.primary.minimum_duration || 1,
            maxDuration: this.data.primary.maximum_duration || 180,
        };

        const slidersConfig = {
            carCost: {
                calcKey: 'carCost',
                label: this.$translate('calc_car_cost_label', 'Bilens kostnad'),
                minValue: minMaxValues.minCarCost,
                maxValue: minMaxValues.maxCarCost,
                startValue: calculatorValues.carCost,
                increment: this.getIncrement(minMaxValues.minCarCost, minMaxValues.maxCarCost),
                suffix: 'kr',
                maxFallback: minMaxValues.maxCarCost * 10,
            },
            downPayment: {
                calcKey: 'downPayment',
                label: this.$translate('calc_car_down_payment_label', 'Kontantinsats'), 
                minValue: minMaxValues.minDownPayment,
                maxValue: minMaxValues.maxDownPayment,
                startValue: calculatorValues.downPayment,
                increment: 1,
                suffix: '%',
                maxDecimals: 2,
            },
            loanAmount: {
                calcKey: 'loanAmount',
                label: this.$translate('calc_private_loan_amount_label', 'Lånebelopp'),
                minValue: minMaxValues.minLoanAmount,
                maxValue: minMaxValues.maxLoanAmount,
                startValue: calculatorValues.loanAmount,
                increment: this.getIncrement(minMaxValues.minLoanAmount, minMaxValues.maxLoanAmount),
                suffix: 'kr',
                maxFallback: minMaxValues.maxLoanAmount * 10,
            },
            interest: {
                calcKey: 'interest',
                label: this.$translate('calc_interest_label', 'Ränta'),
                minValue: minMaxValues.minInterest,
                maxValue: minMaxValues.maxInterest,
                startValue: calculatorValues.interest,
                increment: 0.01,
                suffix: '%',
                maxDecimals: 2,
            },
            duration: {
                calcKey: 'duration',
                label: this.$translate('calc_duration_label', 'Löptid'),
                minValue: minMaxValues.minDuration,
                maxValue: minMaxValues.maxDuration,
                startValue: calculatorValues.duration,
                increment: 1,
                suffix: 'mån',
            }
        };
        const sliders = isCarLoan ? pick(slidersConfig, ['carCost', 'downPayment', 'interest', 'duration']) : pick(slidersConfig, ['loanAmount', 'interest', 'duration']);

        return {
            ...calculatorValues,
            ...minMaxValues,
            showTable: false,
            isCarLoan: isCarLoan,
            calculatorId: this.data.id,
            sliders,
            showHighlightedLenders: this.data.primary.show_highlighted_lenders,
        };
    },
    computed: {
        inputSectionHeader() {
            if (this.isCarLoan) {
                return this.$translate('calc_car_input_section_h3', 'Ditt lån');
            }
            return this.$translate('calc_private_loan_input_section_h3', 'Ditt lån');
        },
        interestCost() {
            const monthlyInterestRate = (this.interest/100) / 12;
            let currentCost = 0;
            for (let i = this.duration; i > 0; i--) {
                currentCost += this.loanAmount * (i/this.duration) * monthlyInterestRate;
            }
            return currentCost;
        },
        interestDeduction() {
            if (this.$isNorway()) {
                return this.interestCost * 0.22;
            }
            return this.interestCost * 0.3;
        },
        totalToPay() {
            return this.loanAmount + (this.interestCost - this.interestDeduction);
        },
        monthlyPayments() {
            return this.totalToPay / this.duration;
        },
    },
    watch: {
        carCost() {
            this.loanAmount = this.getCarLoanAmount();
        },
        downPayment() {
            this.loanAmount = this.getCarLoanAmount();
        },
    },
    methods: {
        setValues(data) {
            this[data.key] = data.value;
        },
        getCarLoanAmount() {
            return this.carCost * (1 - this.downPayment/100);
        },
        getIncrement(min, max) {
            const range = max - min;

            if (range >= 1000000) {
                return 5000;
            }
            else if (range <= 20000) {
                return 500;
            }
            return 1000;
        }
    }
};
</script>

<style lang="scss" scoped>
    .section-heading{
        margin-bottom: 2rem;
    }
    .calculator-container{
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        @include device(pad) {
            grid-template-columns: 3fr 2fr;
            gap: 1rem;
        }
        .calculator-box {
            box-shadow: $bxs;
            @include spacing(padding, 4);
            @include border-radius(3px);
            h3 {
                text-align: center;
            }
        }
    }
    .details-container {
        margin-top: 20px;
        @include border-radius(3px);
        box-shadow: $bxs;
        .switch-container {
            @include spacing(padding, 4);
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }
</style>
