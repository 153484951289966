<template>
    <Section
        class="banner bg-cream"
        width="lg"
    >
        <div class="container">
            <div>
                <h1 class="banner-inner__title green">{{ $prismic.asText(data.primary.banner_h1) }}</h1>

                <div
                    class="banner-inner__teaser list-icon list-checkmark"
                    v-html="$prismic.asHtml(data.primary.banner_teaser)"
                />

                <div
                    class="banner-inner__dropdowns"
                >
                    <Dropdown
                        v-for="(dropdown, index) in dropdowns"
                        :key="index"
                        :header="dropdown.header"
                        :data="$prismic.asHtml(dropdown.content)"
                    />
                </div>
            </div>
        </div>

        <div class="container">
            <div class="lenders-wrapper">
                <div 
                    v-for="lender in lenders"
                    :key="lender.id"
                    class="lender-box"
                >
                    <div class="lender-content">
                        <div class="image-wrapper">
                            <nuxt-link :to="$prismic.linkResolver(lender)">
                                <PrismicImage
                                    :img="lender.data.image"
                                    w="150"
                                />
                            </nuxt-link>
                            <div class="rating">
                                <i class="fa-solid fa-star green rating-icon" />
                                <span>
                                    <span class="score">{{ lenders[1].model._ratings.average_score.toFixed(1) }}</span>/5
                                </span>
                            </div>
                        </div>
                        <div class="highlighted-data">
                            <div 
                                v-for="(highlightedData, index) in getHighlightedData(lender)" 
                                :key="index"
                                class="data-item"
                            >
                                <span class="header">{{ highlightedData.title }}</span>
                                <span class="value">
                                    {{ highlightedData.value }}
                                    <Tooltip
                                        v-if="highlightedData.showTooltip" 
                                        :data="highlightedData.tooltipData"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="button-wrapper">
                        <ButtonTarget
                            :link="lender"
                            :text="$translate('cta_to_application', 'Till ansökan')"
                            background="green"
                            size="md"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
import Dropdown from '@/components/slices/banners/components/Dropdown.vue';
import Tooltip from '@/components/features/Tooltip.vue';
export default {
    components: { Dropdown, Tooltip },
    props: {
        data: {
            type: Object,
            required: true
        },
        lenders: {
            type: Array,
            required: true
        }
    },
    computed: {
        dropdowns() {
            return [
                {
                    header: this.data.primary.banner_dropdown_1_header,
                    content: this.data.primary.banner_dropdown_1_content
                },
                {
                    header: this.data.primary.banner_dropdown_2_header,
                    content: this.data.primary.banner_dropdown_2_content
                },
                {
                    header: this.data.primary.banner_dropdown_3_header,
                    content: this.data.primary.banner_dropdown_3_content
                }
            ].filter(dropdown => this.$validateText(dropdown.header));
        }
    },
    methods: {
        getHighlightedData(lender) {
            return [
                {
                    title: this.$translate('amount', 'Belopp'),
                    value: lender.model.amountString,
                    showTooltip: lender.model.hasDebtCollectionAmount,
                    tooltipData: this.$translate('debt_collection_amount_tooltip', 'Vid ett samlingslån gäller istället {amount}')
                        .replace('{amount}', lender.model.amountDebtCollectionString)
                },
                {
                    title: this.$translate('effective_interest', 'Effektiv ränta'),
                    value: lender.model.effectiveInterestString
                },
                {
                    title: this.$translate('connected_banks', 'Anslutna långivare'),
                    value: lender.model.connectedBanks
                }
            ]
        }
    }
};
</script>

<style lang="scss" scoped>

.banner::v-deep {
    &:after {
        top: 25%;
        @include size(300px);

        @include device(pad) {
            left: -125px;
            top: calc( 100% - 225px );
            @include size(600px);
        }
    }

    .section-inner {
        @include grid(1, 40px);

        @include device(desktop) {
            @include flex;
        }
        @include device(pad) {
            align-items: flex-start!important;
        }

        .container {
            @include device(pad) { flex: 1; }

            &:first-child {
                @include flex;

                .list-icon {
                    ul { @include grid(1, 20px); }
                }

                .banner-inner__dropdowns {
                    @include spacing(margin, 8, top);
                    width: 100%;
                    @include grid(1,10px);

                    .dropdown {
                        background: $white;
                        @include border-radius(5px);
                        box-shadow: $bxs;

                        &-header {
                            @include flex;
                            cursor: pointer;
                            @include spacing(padding,3);
                            @include font-size(16px);
                            font-family: $fontHeader;
                            color: $green;

                            &:after {
                                content: "\f0d7";
                                font: var(--fa-font-solid);
                                font-size: 22px;
                                color: $font;
                                transition: .3s ease-in-out;
                            }

                            span {
                                flex: 1;
                                position: relative;
                            }
                        }

                        &-body {
                            @include border(top);
                            @include spacing(padding,4);

                            p {
                                &:first-child {
                                    margin-top: 0;
                                }
                            }
                        }
                    }

                    .dropdown.open {
                        .dropdown-header:after { transform: rotate(180deg); }
                    }
                }
            }
        }
    }
    .container:last-child {
        @include grid(1, 30px);
        .lenders-wrapper {
            z-index: 1;
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .lender-box {
                background: $white;
                box-shadow: $bxs;
                @include border-radius(5px);
                .lender-content {
                    display: flex;
                    flex-direction: column;
                    @include device(pad) {
                        flex-direction: row;
                        align-items: center;
                    }
                    .image-wrapper {
                        @include spacing(padding, 4);
                        padding-bottom: 0 !important;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        .rating {
                            margin-top: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;
                            .rating-icon {
                                font-size: 18px;
                            }
                            .score {
                                font-weight: bold;
                                font-size: 18px;
                            }
                        }
                    }
                    .highlighted-data {
                        @include spacing(padding, 4);
                        padding-bottom: 0 !important;
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;
                        .data-item {
                            display: flex;
                            justify-content: space-between;
                            padding: 4px 0;
                            border-bottom: 1px solid #f3f3f3;
                            .header {
                                font-weight: bold;
                            }
                            .value {
                                text-align: right;
                            }
                        }
                    }
                }
                .button-wrapper {
                    display: flex;
                    @include spacing(padding, 4);
                    a {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>