<template>
    <tr>
        <th class="fw-bold">{{ title }}</th>
        <td v-for="(item, index) in items" :key="`${index}`">
            {{ item.value }}
            <Tooltip
                v-if="item.showTooltip"
                :data="item.tooltipData"
            />
        </td>
    </tr>
</template>
<script>
import Tooltip from '@/components/features/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
