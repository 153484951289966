<template>
    <ListTemplate
        :data="data"
        :list="originalList"
        :stats-text="statsText"
        :interest-text="interestText"
        :toplist-header="toplistHeader"
        :toplist-text="toplistText"
        :structured-data-enabled="Boolean(filteredList.length)"
    >
        <template #filter> 
            <filter-form 
                :filters="filters"
                :list-length="filteredList.length"
                :unfiltered-list-length="listToFilter.length"
                :active-filters-length="activeFilters.length"
                @updateCheckboxValue="updateCheckboxValue"
                @updateSliderValue="updateSliderValue"
            />
        </template>
        <template #list>
            <ul class="lender-list">
                <Lender
                    v-for="(lender, index) in filteredListCapped"
                    :id="lender.id"
                    :key="lender.id"
                    :position="index + 1"
                    :alternate-data-config="{
                        dataType: data.primary.alternate_data,
                        dataDisplay: data.primary.alternate_data_display
                    }"
                />
            </ul>
            <button 
                v-if="data.primary.show_filter && !showAll && !useOriginalList && filteredList.length > 10" 
                class="btn btn-md btn-green show-more-button" 
                @click="showAll = true"
            >
                {{ $translate('filter_list_show_all', 'Visa alla') }}
            </button>
        </template>

        <template #stats>
            <tbody>
                <tr>
                    <td>{{ $translate('bl_stats_min_amount', 'Lägsta lånebelopp') }}</td>
                    <td>{{ $format.currency(stats.minAmount, 2) }}</td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_stats_max_amount', 'Högsta lånebelopp') }}</td>
                    <td>{{ $format.currency(stats.maxAmount, 2) }}</td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_stats_average_min_interest', 'Genomsnittligt frånränta') }}</td>
                    <td>{{ $format.percentage(stats.averageFromInterest, 2) }}</td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_stats_average_max_interest', 'Genomsnittligt tillränta') }}</td>
                    <td>{{ $format.percentage(stats.averageToInterest, 2) }}</td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_stats_average_setup_fee', 'Genomsnittlig uppläggningsavgift') }}</td>
                    <td>{{ $format.currency(stats.setupFee, 2) }}</td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_stats_average_admin_fee', 'Genomsnittlig administrationsavgift') }}</td>
                    <td>{{ $format.currency(stats.adminFee, 2) }}</td>
                </tr>
                <tr>
                    <td>{{ $translate('direct_payout', 'Direktutbetalning') }}</td>
                    <td>
                        {{ 
                            $translate('bl_stats_direct_payout_data', '{amountOfLenders} långivare ({procent}) erbjuder direktutbetalning')
                                .replace('{amountOfLenders}', stats.countHasDirectPayments)
                                .replace('{procent}', $format.percentage(100 * stats.hasDirectPayments, 2)) 
                        }}
                    </td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_stats_direct_payout_24_7', 'Direktutbetalning dygnet runt') }}</td>
                    <td>
                        {{ 
                            $translate('bl_stats_direct_payout_24_7_data', '{amountOfLenders} långivare ({procent}) erbjuder direktutbetalning dygnet runt')
                                .replace('{amountOfLenders}', stats.countHasDirectPayments24Hours)
                                .replace('{procent}', $format.percentage(100 * stats.hasDirectPayments24Hours, 2))   
                        }} 
                    </td>
                </tr>
                <tr>
                    <td>{{ $translate('without_uc', 'Utan UC') }}</td>
                    <td>
                        {{ 
                            $translate('bl_stats_without_uc_data', '{amountOfLenders} långivare ({procent}) använder inte kreditupplysningar från UC')
                                .replace('{amountOfLenders}', stats.countDoesntTakesUC)
                                .replace('{procent}', $format.percentage(100 * (1 - stats.takesUC), 2))
                        }}
                    </td>
                </tr>
                <tr>
                    <td>{{ $translate('ld_payment_remarks_label', 'Betalningsanmärkningar') }}</td>
                    <td>
                        {{ 
                            $translate('bl_stats_payment_remarks_data', '{amountOfLenders} långivare {procent} accepterar betalningsanmärkningar.')
                                .replace('{amountOfLenders}', stats.countAcceptsPaymentRemarks)
                                .replace('{procent}', $format.percentage(100 * stats.acceptsPaymentRemarks, 2))
                        }}
                    </td>
                </tr>
                <tr>
                    <td>{{ $translate('bl_stats_young_borrower', 'Unga låntagare') }}</td>
                    <td>
                        {{ 
                            $translate('bl_stats_young_borrower_data', '{amountOfLenders} långivare {acceptYoung} accepterar låntagare under 20 år')
                                .replace('{amountOfLenders}', stats.countAcceptsYoung)
                                .replace('{acceptYoung}', $format.percentage(100 * stats.acceptsYoung, 2))
                        }}
                    </td>
                </tr>
            </tbody>
        </template>

        <template #interests>
            <thead>
                <tr>
                    <th>{{ $translate('bl_interests_lenders', 'Långivare') }}</th>
                    <th>
                        <span>{{ $translate('bl_interests_from', 'Lägsta') }}</span>
                        <span>{{ $translate('bl_interests_to', 'Högsta') }}</span>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="(lender, index) in filteredListCapped.filter($helpers.filterEmptyElements)"
                    :key="index"
                >
                    <td><nuxt-link :to="$prismic.linkResolver(lenderData(lender.id))">{{ lenderData(lender.id).data.title }}</nuxt-link></td>
                    <td>
                        <div
                            class="bar"
                            :style="barStyling(lenderModel(lender.id))"
                        >
                            <div class="bar-text"> {{ lenderModel(lender.id).minInterestPossibleString }}</div>
                            <div class="bar-text"> {{ lenderModel(lender.id).maxInterestPossibleString }}</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </template>
        
        <template #toplist>
            <ol>
                <toplist-item 
                    v-for="(lender, index) in filteredListCapped.slice(0, 10)" 
                    :key="lender.id" 
                    :fixed-amount="data.primary.toplist_fixed_amount" 
                    :lender-id="lender.id" 
                    :position="index + 1"
                    :alternate-data-keys="alternateDataKeys"
                />
            </ol>
        </template>
    </ListTemplate>
</template>

<script>
import ListTemplate from './ListTemplate.vue';
import map from 'lodash/map';
import Lender from '@/components/slices/lists/list-items/ListItemLender.vue';
import ToplistItem from './ToplistItem.vue';
import FilterForm from '@/components/slices/lists/filter/index.vue';

import FilterLogic from '@/components/slices/lists/filter/mixins.js';

export default {
    components: { 
        ListTemplate, 
        Lender,
        ToplistItem,
        FilterForm
    },
    mixins: [FilterLogic],
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            graphMin: 0,
            alternateDataKeys: this.$getAlternateDataKeys(this.data.primary.alternate_data, this.data.primary.alternate_data_display)
        };
    },
    computed: {
        list() {
            let lenderList;
            if (this.data.primary.predefined_list.id) {
                let list = this.$store.state.lists[this.data.primary.predefined_list.id];
                lenderList = map(list.data.lenders, 'lender');
            } 
            else {
                lenderList = map(this.data.items, 'lender');
            }
            
            if (this.data.primary.amount) {
                lenderList = lenderList.filter(lender => {
                    const model = this.lenderModel(lender.id);
                    return model[this.alternateDataKeys.minAmount] <= this.data.primary.amount &&
                        model[this.alternateDataKeys.maxAmount] >= this.data.primary.amount;
                });
            }
            return lenderList.filter(lender => lender.id);
        },

        statsText() {
            if (this.data.primary.stats_text.length > 0) {
                return this.$prismic.asHtml(this.data.primary.stats_text);
            }
            return '<p>' + this.$translate('bl_stats_description', 'För att hjälpa dig att fatta rätt beslut finns här en sammaställning av långivarnas egenskaper.') + '</p>';
        },

        interestText() {
            if (this.data.primary.interests_text.length > 0) {
                return this.$prismic.asHtml(this.data.primary.interests_text);
            }
            return '<p>' + this.$translate('bl_interests_description', 'Här kan du se hur långivarnas räntor står sig mot varandra.') + '</p>';
            
        },

        toplistHeader() {

            const header = this.$prismic.asText(this.data.primary.toplist_h2);
            if (header) {
                return header;
            }
            return this.$translate('bl_toplist_h2', 'Topplista');
        },

        toplistText() {
            if (this.data.primary.toplist_text.length > 0) {
                return this.$prismic.asHtml(this.data.primary.toplist_text);
            }
            return '<p>' + this.$translate('bl_toplist_summary_description', 'Sammanfattning över vår topplista.') + '</p>';
        },

        stats() {
            let count = 0;

            // averages
            let sumMinInterestPossible = 0;
            let sumMaxInterestPossible = 0;
            let sumSetupFee = 0;
            let sumAdminFee = 0;

            // counts
            let countUC = 0;
            let countAcceptsPaymentRemarks = 0;
            let countYoung = 0;
            let countDirectPayments = 0;
            let countDirect24HoursPayments = 0;

            // other
            let minAmount = 500000;
            let maxAmount = 0;


            Object.values(this.filteredListCapped).forEach(lender => {

                if (lender.type !== 'lender') {
                    // should not happen but just for safety
                    return;
                }

                const model = this.lenderModel(lender.id);
                const lenderMinAmount = model[this.alternateDataKeys.minAmount];
                const lenderMaxAmount = model[this.alternateDataKeys.maxAmount];

                count++;

                sumMinInterestPossible += model.minInterestPossible;
                sumMaxInterestPossible += model.maxInterestPossible;
                sumSetupFee += model.setupFee;
                sumAdminFee += model.administrationFee;

                if (model.takesUC) {
                    countUC++;
                }

                if (model.acceptsPaymentRemarks) {
                    countAcceptsPaymentRemarks++;
                }

                if (model.minAge < 20) {
                    countYoung++;
                }

                if (model.hasDirectPayments) {
                    countDirectPayments++;
                }

                if (model.hasDirectPayments24Hours) {
                    countDirect24HoursPayments++;
                }

                if (lenderMaxAmount > maxAmount) {
                    maxAmount = lenderMaxAmount;
                }

                if (lenderMinAmount < minAmount) {
                    minAmount = lenderMinAmount;
                }
            });

            return {
                averageFromInterest: sumMinInterestPossible / count,
                averageToInterest: sumMaxInterestPossible / count,
                setupFee: sumSetupFee / count,
                adminFee: sumAdminFee / count,

                takesUC: countUC / count,
                countDoesntTakesUC: count - countUC,

                acceptsPaymentRemarks: countAcceptsPaymentRemarks / count,
                countAcceptsPaymentRemarks: countAcceptsPaymentRemarks,

                acceptsYoung: countYoung / count,
                countAcceptsYoung: countYoung, 

                hasDirectPayments: countDirectPayments / count,
                countHasDirectPayments: countDirectPayments,

                hasDirectPayments24Hours: countDirect24HoursPayments / count,
                countHasDirectPayments24Hours: countDirect24HoursPayments,

                minAmount: minAmount,
                maxAmount: maxAmount
            };
        },

        maxInterest() {
            let max = 0;
            Object.values(this.filteredListCapped).forEach(lender => {

                if (lender.type !== 'lender') {

                    // should not happen but just for safety
                    return;
                }

                const model = this.lenderModel(lender.id);
                const modelMax = model.maxInterestPossible;
                max = modelMax > max ? modelMax : max;
            });

            return max;
        }
    },
    methods: {
        barStyling(model) {

            const width = model.isFixedInterest ? 1 :
                (model.maxInterestPossible - model.minInterestPossible) / this.maxInterest * 100 + 1;

            const margin = (model.minInterestPossible - this.graphMin) / this.maxInterest * 100;

            return `width: ${width}%; margin-left: ${margin}%;`;
        },

        lenderData(id) {
            return this.$store.state.lenders[id];
        },

        lenderModel(id) {
            return new this.$models.Loan(this.lenderData(id), this.$store);
        }
    }
};
</script>
