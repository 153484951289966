<template>
    <Section
        class="list"
        width="md"
    >
        <h2
            v-if="$validateText(data.primary.h2)"
            class="section-header"
        >
            {{ $prismic.asText(data.primary.h2) }}
        </h2>
        <div 
            v-if="$validateText(data.primary.teaser)" 
            class="rich-text" 
            v-html="$prismic.asHtml(data.primary.teaser)" 
        />

        <ul>
            <Post
                v-for="post in posts"
                :key="post.id"
                :post="post"
            />
        </ul>
    </Section>
</template>

<script>
import Post from '@/components/slices/lists/list-items/ListItemSurvey.vue';
import { queryAll, replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: { Post },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            posts: []
        };
    },
    async fetch() {
        const posts = replace(await queryAll(this.$prismic.api, 
            [
                this.$prismic.predicates.at('document.type', 'post'),
                this.$prismic.predicates.at('my.post.post_category', 'undersökningar')
            ],
            { 
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[ document.last_publication_date desc ]' 
            }
        ));
        this.posts = posts;
    }
};
</script>
<style lang="scss" scoped>
.section-header {
    @include spacing(margin,4,bottom);
}
    ul {
        @include grid(1, 20px);
        @include spacing(padding,2,top);
    }
</style>
