<template>
    <li>
        <!-- TODO Maybe use a component somewhere -->
        <a :href="$helpers.getRedirectUrl(lender, 'list-text-toplist', position)" target="_blank" rel="nofollow">
            <span>{{ lender.data.title }}</span>: {{ text }}
        </a>
    </li>
</template>
<script>
export default {
    props: {
        lenderId: {
            type: String,
            required: true
        },
        fixedAmount: {
            type: Number,
            required: false,
            default: 0,
        },
        position: {
            type: Number, 
            required: true,
        },
        alternateDataKeys: {
            type: Object,
            required: true,
        }
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.lenderId];
        },
        model() {
            return new this.$models.Loan(this.lender);
        },
        text() {
            const textSegments = [];

            textSegments.push(this.loanText);

            if (this.$isSweden()) {
                textSegments.push(this.ucAndPaymentRemarksText);
            }

            textSegments.push(this.interestText);

            return textSegments
                .filter(segment => segment)
                .join(' ');
        },
        loanText() {
            let loanAmount;
            const { minAmount: minAmountKey, maxAmount: maxAmountKey, amountString: amountStringKey } = this.alternateDataKeys;

            if (this.fixedAmount && this.fixedAmount >= this.model[minAmountKey] && this.fixedAmount <= this.model[maxAmountKey]) {
                loanAmount = this.$format.currency(this.fixedAmount);
            }
            else {
                loanAmount = this.model[amountStringKey];
            }
            
            return this.$translate('bl_toplist_loan_amount', 'Låna {amount}')
                .replace('{amount}', loanAmount);
        },
        ucAndPaymentRemarksText() {
            if (! this.model.takesUC && this.model.acceptsPaymentRemarks) {
                return this.$translate('bl_toplist_without_uc_and_despite_payment_remarks', 'utan UC och trots anmärkning');
            }
            if (! this.model.takesUC) {
                return this.$translate('bl_toplist_without_uc', 'utan UC');
            }
            if (this.model.acceptsPaymentRemarks) {
                return this.$translate('bl_toplist_despite_payment_remarks', 'trots anmärkning');
            }
            return '';
        },
        interestText() {
            return this.$translate('bl_toplist_with_interest', 'med ränta {interest}')
                .replace('{interest}', this.model.interestString);
        }
    }
};
</script>