var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListTemplate',{attrs:{"data":_vm.data,"list":_vm.originalList,"stats-text":_vm.statsText,"interest-text":_vm.interestText,"toplist-header":_vm.toplistHeader,"toplist-text":_vm.toplistText,"structured-data-enabled":Boolean(_vm.filteredList.length)},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('filter-form',{attrs:{"filters":_vm.filters,"list-length":_vm.filteredList.length,"unfiltered-list-length":_vm.listToFilter.length,"active-filters-length":_vm.activeFilters.length},on:{"updateCheckboxValue":_vm.updateCheckboxValue,"updateSliderValue":_vm.updateSliderValue}})]},proxy:true},{key:"list",fn:function(){return [_c('ul',{staticClass:"lender-list"},_vm._l((_vm.filteredListCapped),function(lender,index){return _c('Lender',{key:lender.id,attrs:{"id":lender.id,"position":index + 1,"alternate-data-config":{
                    dataType: _vm.data.primary.alternate_data,
                    dataDisplay: _vm.data.primary.alternate_data_display
                }}})}),1),_vm._v(" "),(_vm.data.primary.show_filter && !_vm.showAll && !_vm.useOriginalList && _vm.filteredList.length > 10)?_c('button',{staticClass:"btn btn-md btn-green show-more-button",on:{"click":function($event){_vm.showAll = true}}},[_vm._v("\n            "+_vm._s(_vm.$translate('filter_list_show_all', 'Visa alla'))+"\n        ")]):_vm._e()]},proxy:true},{key:"stats",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_stats_min_amount', 'Lägsta lånebelopp')))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.$format.currency(_vm.stats.minAmount, 2)))])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_stats_max_amount', 'Högsta lånebelopp')))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.$format.currency(_vm.stats.maxAmount, 2)))])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_stats_average_min_interest', 'Genomsnittligt frånränta')))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.$format.percentage(_vm.stats.averageFromInterest, 2)))])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_stats_average_max_interest', 'Genomsnittligt tillränta')))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.$format.percentage(_vm.stats.averageToInterest, 2)))])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_stats_average_setup_fee', 'Genomsnittlig uppläggningsavgift')))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.$format.currency(_vm.stats.setupFee, 2)))])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_stats_average_admin_fee', 'Genomsnittlig administrationsavgift')))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.$format.currency(_vm.stats.adminFee, 2)))])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('direct_payout', 'Direktutbetalning')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_stats_direct_payout_data', '{amountOfLenders} långivare ({procent}) erbjuder direktutbetalning')
                            .replace('{amountOfLenders}', _vm.stats.countHasDirectPayments)
                            .replace('{procent}', _vm.$format.percentage(100 * _vm.stats.hasDirectPayments, 2)))+"\n                ")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_stats_direct_payout_24_7', 'Direktutbetalning dygnet runt')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_stats_direct_payout_24_7_data', '{amountOfLenders} långivare ({procent}) erbjuder direktutbetalning dygnet runt')
                            .replace('{amountOfLenders}', _vm.stats.countHasDirectPayments24Hours)
                            .replace('{procent}', _vm.$format.percentage(100 * _vm.stats.hasDirectPayments24Hours, 2)))+" \n                ")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('without_uc', 'Utan UC')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_stats_without_uc_data', '{amountOfLenders} långivare ({procent}) använder inte kreditupplysningar från UC')
                            .replace('{amountOfLenders}', _vm.stats.countDoesntTakesUC)
                            .replace('{procent}', _vm.$format.percentage(100 * (1 - _vm.stats.takesUC), 2)))+"\n                ")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('ld_payment_remarks_label', 'Betalningsanmärkningar')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_stats_payment_remarks_data', '{amountOfLenders} långivare {procent} accepterar betalningsanmärkningar.')
                            .replace('{amountOfLenders}', _vm.stats.countAcceptsPaymentRemarks)
                            .replace('{procent}', _vm.$format.percentage(100 * _vm.stats.acceptsPaymentRemarks, 2)))+"\n                ")])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$translate('bl_stats_young_borrower', 'Unga låntagare')))]),_vm._v(" "),_c('td',[_vm._v("\n                    "+_vm._s(_vm.$translate('bl_stats_young_borrower_data', '{amountOfLenders} långivare {acceptYoung} accepterar låntagare under 20 år')
                            .replace('{amountOfLenders}', _vm.stats.countAcceptsYoung)
                            .replace('{acceptYoung}', _vm.$format.percentage(100 * _vm.stats.acceptsYoung, 2)))+"\n                ")])])])]},proxy:true},{key:"interests",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$translate('bl_interests_lenders', 'Långivare')))]),_vm._v(" "),_c('th',[_c('span',[_vm._v(_vm._s(_vm.$translate('bl_interests_from', 'Lägsta')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$translate('bl_interests_to', 'Högsta')))])])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.filteredListCapped.filter(_vm.$helpers.filterEmptyElements)),function(lender,index){return _c('tr',{key:index},[_c('td',[_c('nuxt-link',{attrs:{"to":_vm.$prismic.linkResolver(_vm.lenderData(lender.id))}},[_vm._v(_vm._s(_vm.lenderData(lender.id).data.title))])],1),_vm._v(" "),_c('td',[_c('div',{staticClass:"bar",style:(_vm.barStyling(_vm.lenderModel(lender.id)))},[_c('div',{staticClass:"bar-text"},[_vm._v(" "+_vm._s(_vm.lenderModel(lender.id).minInterestPossibleString))]),_vm._v(" "),_c('div',{staticClass:"bar-text"},[_vm._v(" "+_vm._s(_vm.lenderModel(lender.id).maxInterestPossibleString))])])])])}),0)]},proxy:true},{key:"toplist",fn:function(){return [_c('ol',_vm._l((_vm.filteredListCapped.slice(0, 10)),function(lender,index){return _c('toplist-item',{key:lender.id,attrs:{"fixed-amount":_vm.data.primary.toplist_fixed_amount,"lender-id":lender.id,"position":index + 1,"alternate-data-keys":_vm.alternateDataKeys}})}),1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }