<template>
    <Section v-if="items.length" width="sm">
        <div 
            v-if="$validateText(data.primary.text)"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.text)"
        />
        <ul>
            <li
                v-for="(item, index) in items"
                :key="index"
            >
                <RichTextLenderBox
                    :id="item.lender.id"
                    :lender-type="item.lender.type"
                    :alternate-data-config="{
                        dataType: data.primary.alternate_data,
                        dataDisplay: data.primary.alternate_data_display
                    }"
                />
            </li>
        </ul>
    </Section>
</template>

<script>
import RichTextLenderBox from '@/components/slices/rich-text/RichTextLenderBox.vue';
export default {
    components: { RichTextLenderBox },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        items() {
            return this.data.items.filter(item => item.lender.id);
        }
    }
};
</script>

<style lang="scss" scoped>
    section {
        .rich-text {
            @include spacing(margin, 3, bottom);
        }
        ul {
            @include grid(1, 20px);
        }
    }
</style>
