<template>
    <Section
        class="list bg-cream"
        width="lg"
    >
        <h2 class="section-header">{{ h2 }}</h2>
        <div v-if="data.primary.lender_type === 'lendersMortgage'" class="switch-container">
            <span 
                :class="{'active': !showAverageInterest}" 
                @click="showAverageInterest = false"
            >
                {{ $translate('lm_list_interest_header', 'Listränta') }}
            </span>
            <span 
                :class="{'active': showAverageInterest}" 
                @click="showAverageInterest = true"
            >
                {{ $translate('lm_average_interest_header', 'Snittränta') }}
            </span>
        </div>
        <ul>
            <component
                :is="lenderType(lender.type)"
                v-for="(lender, index) in list"
                :id="lender.id"
                :key="index"
                :position="index + 1"
                :show-average-interest="data.primary.lender_type === 'lendersMortgage' && (showAverageInterest)"
                :alternate-data-config="data.primary.lender_type === 'lenders' && {
                    dataType: data.primary.alternate_data,
                    dataDisplay: data.primary.alternate_data_display
                }"
            />
        </ul>
    </Section>
</template>

<script>
import Lender from '@/components/slices/lists/list-items/ListItemLender.vue';
import LenderMortgage from '@/components/slices/lists/list-items/ListItemMortgage.vue';
import LenderCorporate from '@/components/slices/lists/list-items/ListItemCorporate.vue';
export default {
    components: { 
        Lender, 
        LenderMortgage, 
        LenderCorporate, 
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            showAverageInterest: false
        };
    },
    computed: {
        list() {
            return Object.values(this.$store.state[this.data.primary.lender_type]);
        },
        h2() {
            return this.$prismic.asText(this.data.primary.h2)
                .replace(/%count%/g, Object.values(this.list).length);
        }
    },
    methods: {
        lenderType(type) {
            if (type.includes('_')) {
                let split = type.split('_');
                for (let i = 0; i < split.length; i++) {
                    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
                }

                return split.join('');
            }

            return type;
        }
    }
};
</script>
<style lang="scss" scoped>
    ul {
        width: 100%;
        @include grid(1, 40px);
    }
    .switch-container {
        border-radius: 3px;
        display: flex;
        gap: 0.5rem;
        padding: 7px;
        background-color: $lightgray;
        width: fit-content;
        @include spacing(margin, 2, bottom);
        span {
            border-radius: 3px;
            padding: 4px 8px;
            cursor: pointer;
            &:hover {
                box-shadow: $bxs;
            }
        }
        .active {
            box-shadow: $bxs;
            background: $white;
        }
    }
</style>
