<template>
    <span>
        {{ loanAmount }}
        <Tooltip 
            v-if="showTooltip" 
            :data="tooltipText" 
        />
    </span>
</template>

<script>
import Tooltip from '@/components/features/Tooltip.vue';
export default {
    components: {
        Tooltip
    },
    props: {
        model: {
            type: Object,
            required: true
        },
        dataType: {
            type: String,
            required: false,
            default: ''
        },
        dataDisplay: {
            type: String,
            required: false,
            default: ''
        },
        dataKey: {
            type: String,
            required: false,
            default: 'amountString'
        }
    },
    computed: {
        loanAmount() {
            const modelKey = this.$getAlternateDataKeys(this.dataType, this.dataDisplay)[this.dataKey];
            return this.model[modelKey];
        },
        showTooltip() {
            return this.dataType === 'debt_collection' && this.dataDisplay === 'tooltip' && this.model.hasDebtCollectionAmount;
        },
        tooltipText() {
            return this.$translate('debt_collection_amount_tooltip', 'Vid ett samlingslån gäller istället {amount}')
                .replace('{amount}', this.model.amountDebtCollectionString);
        }
    }
};
</script>