export default ({ app }, inject) => {

    inject('isNorway', isNorway);
    inject('isSweden', isSweden);
    
    const getRegistrationNumber = (lender) => {
        const registrationNumber = lender.data.core_api.brand_data.company_registration_number;
        return registrationNumber?.replace('-', '').replace(' ', '');
    };
    inject('getRegistrationNumber', getRegistrationNumber);

    const getCompanyName = (lender) => {
        return lender.data.core_api.brand_data.company_name;
    };
    inject('getCompanyName', getCompanyName);

    const calculateInterestCost = (amount, interest, months) => {

        let interestCost = 0;
        const amortizationPerMonth = amount / months;

        while (months-- > 0) {
            interestCost += ((amount * interest) / 12);
            amount -= amortizationPerMonth;
        }
        
        return interestCost;
    };
    inject('calculateInterestCost', calculateInterestCost);

    const formatBoolean = (boolean) => {
        return boolean ? app.$translate('yes', 'Ja') : app.$translate('no', 'Nej');
    };
    inject('formatBoolean', formatBoolean);

    const formatBooleanIcon = (boolean) => {
        return boolean ? 'fa-solid fa-circle-check green' : 'fa-solid fa-circle-xmark red';
    };
    inject('formatBooleanIcon', formatBooleanIcon);

    const validateText = (prismicElement) => {
        if (typeof(prismicElement) === 'string') {
            return Boolean(prismicElement?.trim());
        }
        return Boolean(app.$prismic.asText(prismicElement)?.trim());
    };
    inject('validateText', validateText);

    const getDirectPaymentBanks = (model) => {
        const banks = {
            'hasDirectPaymentToDanskeBank': 'DanskeBank',
            'hasDirectPaymentToHandelsbanken': 'Handelsbanken',
            'hasDirectPaymentToNordea': 'Nordea',
            'hasDirectPaymentToSEB': 'SEB',
            'hasDirectPaymentToSwedbank': 'Swedbank',
        };
        return Object.entries(banks)
            .filter(([key]) => model[key])
            .map(([, value]) => value);
    };
    inject('getDirectPaymentBanks', getDirectPaymentBanks);

    const getCreditCheckString = (creditCheck) => {
        return {
            'creditsafe': 'Creditsafe',
            'bisnode': 'Bisnode',
            'safenode': 'Safenode',
            'uc': 'UC'
        }[creditCheck] || 'unknown';
    };
    inject('getCreditCheckString', getCreditCheckString);

    const removeParagraphs = (element) => {
        if (typeof(element) === 'object') {
            return app.$prismic.asHtml(element)
                .replaceAll('<p>', '')
                .replaceAll('</p>', '');
        }
        return element;
    };
    inject('removeParagraphs', removeParagraphs);

    const getAlternateDataKeys = (dataType, dataDisplay) => {
        if (dataType === 'debt_collection' && dataDisplay === 'override') {
            return {
                minAmount: 'minAmountDebtCollection',
                maxAmount: 'maxAmountDebtCollection',
                minAmountString: 'minAmountDebtCollectionString',
                maxAmountString: 'maxAmountDebtCollectionString',
                amountString: 'amountDebtCollectionString',
                minDuration: 'minDurationDebtCollection',
                maxDuration: 'maxDurationDebtCollection',
                getDurationString: 'getDurationDebtCollectionString',
            };
        }
        return {
            minAmount: 'minAmount',
            maxAmount: 'maxAmount',
            minAmountString: 'minAmountString',
            maxAmountString: 'maxAmountString',
            amountString: 'amountString',
            minDuration: 'minDuration',
            maxDuration: 'maxDuration',
            getDurationString: 'getDurationString',
        };
    };
    inject('getAlternateDataKeys', getAlternateDataKeys);
};


const isNorway = () => {
    return process.env.PRISMIC_LOCALE === 'no';
};

const isSweden = () => {
    return process.env.PRISMIC_LOCALE === 'sv-se';
};
