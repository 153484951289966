<template>
    <Layout
        :lender-data="lender"
        :lender-model="model"
        :class="{'compare-enabled': compareEnabled}"
    >
        <template #info>
            <div>
                <nuxt-link
                    :to="$prismic.linkResolver(lender)"
                    class="container"
                >
                    <PrismicImage
                        :img="lender.data.image"
                        w="100"
                        h="50"
                        :alt="lender.data.title"
                    />
                </nuxt-link>      
                <toggle-compare-button v-if="compareEnabled" :lender-id="lender.id" /> 
            </div>

            <div class="list-item-inner">
                <ul>
                    <li>
                        <span>{{ $translate('amount', 'Belopp') }}</span>
                        <AlternateLoanAmount :model="model" :data-type="alternateDataConfig.dataType" :data-display="alternateDataConfig.dataDisplay" />
                    </li>
                    <li>
                        <span>{{ $translate('effective_interest', 'Effektiv ränta') }}</span>
                        <span>{{ model.effectiveInterestString }}</span>
                    </li>
                    <li>
                        <span>{{ $translate('duration', 'Löptid') }}</span>
                        <AlternateLoanDuration :model="model" :data-type="alternateDataConfig.dataType" :data-display="alternateDataConfig.dataDisplay" />
                    </li>
                </ul>
                <ul v-if="$isSweden()">
                    <li>
                        <span>{{ $translate('credit_check', 'Kreditupplysning') }}</span>
                        <span>{{ creditCheckCompany }}</span>
                    </li>
                    <li>
                        <span>{{ $translate('ld_payment_remarks_label', 'Betalningsanmärkningar') }}</span>
                        <span v-html="$helpers.boolean(model.acceptsPaymentRemarks)" />
                    </li>
                    <li v-if="model.isBroker">
                        <span>{{ $translate('connected_banks', 'Anslutna långivare') }}</span>
                        <span>{{ model.connectedBanks }}</span>
                    </li>
                    <li v-else-if="model.maxAmount <= 50000">
                        <span>{{ $translate('direct_payout', 'Direktutbetalning') }}</span>
                        <span v-if="model.hasDirectPayments24Hours">{{ $translate('twentyfour_seven', 'Dygnet runt') }}<Tooltip v-if="showDirectPaymentsExplanation" :data="directPaymentsExplanation" /></span>
                        <span v-else-if="model.hasDirectPayments">{{ $translate('yes', 'Ja') }}<Tooltip v-if="showDirectPaymentsExplanation" :data="directPaymentsExplanation" /></span>
                        <span v-else>{{ $translate('no', 'Nej') }}</span>
                    </li>
                    <li v-else>
                        <span>{{ $translate('lf_setup_fee_label', 'Uppläggningsavgift') }}</span>
                        <span>{{ model.setupFeeString }}</span>
                    </li>
                </ul>
                <ul v-else-if="$isNorway()">
                    <li>
                        <span>{{ $translate('ld_payment_remarks_label', 'Betalningsanmärkningar') }}</span>
                        <span v-html="$helpers.boolean(model.acceptsPaymentRemarks)" />
                    </li>
                    <li>
                        <span>{{ $translate('ld_age_limit_label', 'Ålderskrav') }}</span>
                        <span>
                            {{ 
                                $translate('ld_age_limit_value', '{minAge} eller äldre')
                                    .replace('{minAge}', model.minAge) 
                            }}
                        </span>
                    </li>
                    <li v-if="model.isBroker">
                        <span>{{ $translate('connected_banks', 'Anslutna långivare') }}</span>
                        <span>{{ model.connectedBanks }}</span>
                    </li>
                    <li v-else>
                        <span>{{ $translate('lf_setup_fee_label', 'Uppläggningsavgift') }}</span>
                        <span>{{ model.setupFeeString }}</span>
                    </li>
                </ul>
            </div>
        </template>

        <template #apply>
            <ButtonTarget
                size="md"
                background="green"
                :text="$translate('cta_to_application', 'Till ansökan')"
                :link="lender"
                placement="list"
                :position="position"
            />
            <div v-if="$isNorway() && lender.data.redirect_enabled" class="compliance-string">Annonslenke</div>
        </template>

        <template #expand>
            <ul>
                <li>
                    <span>{{ $translate('ld_age_limit_label', 'Ålderskrav') }}</span>
                    <span>
                        {{ 
                            $translate('ld_age_limit_value', '{minAge} eller äldre')
                                .replace('{minAge}', model.minAge) 
                        }}
                    </span>
                </li>
                <li> 
                    <span>{{ $translate('interest', 'Ränta') }}</span>
                    <span>{{ model.interestString }}</span>
                </li>
                <li v-if="$isSweden()">
                    <span>{{ $translate('without_uc', 'Utan UC') }}</span>
                    <span>
                        {{ 
                            ! model.takesUC ? $translate('uses_credit_check', 'Ja, använder {creditCheck}')
                                .replace('{creditCheck}', creditCheckCompany) : $translate('no', 'Nej') 
                        }}
                    </span>
                </li>
                <li v-if="$isSweden()">
                    <span>{{ $translate('apply_with_bankid', 'Ansök med BankID') }}</span>
                    <span v-html="$helpers.boolean(model.hasBankId)" />
                </li>
                <li>
                    <span>{{ $translate('lg_payment_remarks_header', 'Betalningsanmärkningar') }}</span>
                    <span>{{ model.acceptsPaymentRemarks ? $translate('accepted', 'Accepteras') : $translate('not_accepted', 'Accepteras inte') }}</span>
                </li>
                <li v-if="$isSweden()">
                    <span>{{ $translate('direct_payout', 'Direktutbetalning') }}</span>
                    <span>{{ directPaymentsInMoreInfoHelper }}</span>
                </li>
                <li>
                    <span>{{ $translate('lf_setup_fee_label', 'Uppläggningsavgift') }}</span>
                    <span>{{ model.setupFeeString }}</span>
                </li>
                <li>
                    <span>{{ $translate('rating', 'Betyg') }}</span>
                    <span>
                        {{ 
                            $translate('average_rating_score', '{averageRating} av 5 ({amount} omdömen)')
                                .replace('{averageRating}', $format.number(model._ratings.average_score, 1))
                                .replace('{amount}', model._ratings.count)
                        }}
                    </span>
                </li>
            </ul>

            <div class="expand-inner">
                <ButtonNuxt
                    :link="lender"
                    size="sm"
                    background="blue"
                    :text="$translate('cta_read_review', 'Läs recension')"
                />

                <!-- TODO: another placement or label or whatever? -->
                <ButtonTarget
                    :link="lender"
                    :text="$translate('cta_apply_direct', 'Ansök direkt')"
                    size="sm"
                    background="green"
                    placement="list"
                    :position="position"
                />
                <div v-if="$isNorway() && lender.data.redirect_enabled" class="compliance-string">Annonslenke</div>
            </div>
        </template>
    </Layout>
</template>

<script>
import Tooltip from '@/components/features/Tooltip.vue';
import AlternateLoanAmount from '@/components/features/AlternateLoanAmount.vue';
import AlternateLoanDuration from '@/components/features/AlternateLoanDuration.vue';
// import { capitalize } from 'lodash';
import capitalize from 'lodash/capitalize';
import Layout from '@/components/slices/lists/list-items/components/Template';
import ToggleCompareButton from '@/components/ToggleCompareButton';
export default {
    components: { Layout, Tooltip, ToggleCompareButton, AlternateLoanAmount, AlternateLoanDuration },
    props: {
        id: {
            type: String,
            required: true
        },
        position: {
            type: Number,
            required: true
        },
        alternateDataConfig: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data() {
        return {
            expand: false
        };
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.id];
        },
        model() {
            return new this.$models.Loan(this.lender, this.$store);
        },
        creditCheckCompany() {
            return capitalize(this.model.creditCheck);
        },
        showDirectPaymentsExplanation() {
            const banks = [
                'Nordea', 'DanskeBank', 'Handelsbanken', 'SEB', 'Swedbank'
            ];

            for (let i = 0; i < banks.length; i++) {
                if (this.model[`hasDirectPaymentTo${banks[i]}`]) {
                    return true;
                }
            }

            return false;
        },
        directPaymentsExplanation() {
            const banks = [
                'Nordea', 'DanskeBank', 'Handelsbanken', 'SEB', 'Swedbank'
            ];

            let hasDirectPaymentTo = [];
            banks.forEach(bank => {
                if (this.model[`hasDirectPaymentTo${bank}`]) {
                    hasDirectPaymentTo.push(bank);
                }
            });
            return this.$translate('direct_payout_to_bank', 'Direktutbetalning till {bank}')
                .replace('{bank}', hasDirectPaymentTo.join(', '));
        },
        directPaymentsInMoreInfoHelper() {
            if (this.model.hasDirectPayments24Hours) {
                return this.showDirectPaymentsExplanation ? this.directPaymentsExplanation : this.$translate('twentyfour_seven', 'Dygnet runt');
            }
            
            if (this.model.hasDirectPayments) {
                return this.showDirectPaymentsExplanation ? this.directPaymentsExplanation : this.$translate('yes', 'Ja');
            }

            return this.$translate('no', 'Nej');
        },
        compareEnabled() {
            return this.$store.state.settings.enable_lender_compare && this.$store.state.settings.lender_compare_page?.id;
        }
    }
};
</script>

<style lang="scss" scoped>
.list-item-inner {
    @include spacing(padding, 4);
    @include grid(1, 0);
    width: 100%;

    @include device(pad) {
        flex: 1;
        width: auto;
        @include grid(2, 30px);
    }

    ul {

        &:last-child {
            @include device(mobile) {
                @include border(top);
            }
        }

        li {
            @include flex;
            @include border(bottom);
            font-size: 14px;
            @include spacing(padding, 2, (bottom,top));

            &:last-child { border: 0; }

            span {
                text-align: left;
                flex: 1;

                @include device(pad) {
                    flex: none;
                    width: 100%;
                }

                @include device(desktop) {
                    width: auto;
                }

                &:last-child::v-deep {
                    text-align: right;
                    width: 100%;
                    font-weight: bold;

                    @include device(pad) {
                        text-align: left;
                        margin-top: 10px;
                    }

                    @include device(desktop) {
                        text-align: right;
                        flex: 1;
                        margin: 0;
                        margin-left: 10px;
                    }

                    i { margin-right: 10px; }
                }
            }
        }
    }
}
@include device(pad) {
    .compare-enabled {
        position: relative;   
        ::v-deep .buttons {
            margin-top: 25px;
        }
    }
}
</style>
