<template>
    <li>
        <nuxt-link :to="$prismic.linkResolver(post)">
            <span class="item-title h4">{{ post.data.title }}</span>
            <span class="item-preamble">{{ preamble }}</span>
        </nuxt-link>
        <span v-if="author" class="author-wrapper">{{ $translate('by', 'av') }}
            <nuxt-link :to="$prismic.linkResolver(author)" class="author"> {{ author.data.name }}</nuxt-link>
        </span>
        <span>
            <i class="fa-regular fa-calendar-lines-pen" />
            {{ publicationDate }}
        </span>
    </li>
</template>

<script>
export default {
    props: {
        post: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            author: this.$store.state.authors[ this.post.data.author.id ],
            publicationDate: this.$format.date(this.post.last_publication_date),
        };
    },
    computed: {
        preamble() {
            const richTextSlice = this.post.data.body.find(slice => slice.slice_type === 'rich_text_1');
            const paragraph = richTextSlice.items[0].content.find(element => element.type === 'paragraph');
            return paragraph?.text || '';
        }
    }
};
</script>

<style lang="scss" scoped>
a {
    @include flex(start, start);
    flex-direction: column;
    gap: 0.5rem;
    text-decoration: none;
    color: $font;
    @include spacing(margin,2,bottom);

    &:hover { text-decoration: underline; }

    .item-title {
        font-family: $fontHeader;
        font-weight: bold;
    }

    .item-preamble {
        @include font-size(14px);
        display: -webkit-box;
        line-clamp: 5;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        @include device(pad) {
            line-clamp: 2;
            -webkit-line-clamp: 2;
        }
    }    
}
.author-wrapper {
    @include spacing(margin,1,right);
    
    a {
        color: $green;
    }

    .author {
        display: inline-block;
        font-weight: bold;
    }
}
</style>
