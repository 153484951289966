<template>
    <div class="list-item">
        <LenderBox
            :id="id"
            :lender-type="lenderType"
            :content="content"
            :alternate-data-config="alternateDataConfig"
        />
    </div>
</template>

<script>
import LenderBox from '@/components/slices/rich-text/RichTextLenderBox';
export default {
    components: { LenderBox },
    props: {
        id: {
            type: String,
            required: true
        },
        lenderType: {
            type: String,
            required: false,
            default: 'lender'
        },
        content: {
            type: Array,
            required: true
        },
        alternateDataConfig: {
            type: Object,
            required: false,
            default: () => ({})
        }
    }
};
</script>